/* eslint-disable camelcase */
import {
    createHearingAid,
    getHearingAidBySide,
    HearingAid,
    HearingAidDto,
    HearingAids,
} from "../../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedHearingAidsSelector";
import { FittingStep } from "../../components/DeviceAssignment/FittingDialogContent";
import { EnvironmentInfoService } from "./EnvironmentInfoService";

export interface HearingAidsFirmwareVersion {
    left: string | undefined;
    right: string | undefined;
}

export interface ConnectionStatus {
    left:
        | {
              id: string;
              isReadyForProgramming: boolean;
              isPhysicallyConnected: boolean;
          }
        | undefined;
    right:
        | {
              id: string;
              isReadyForProgramming: boolean;
              isPhysicallyConnected: boolean;
          }
        | undefined;
}

export interface HearingAidInfo extends HearingAidProductSpecificationInfo {
    id: string;
    currentFirmwareVersion: string;
    serialNumber: string;
    brand: string;
    demo: boolean;
}

export interface HearingAidProductSpecificationInfo {
    family: string;
    model: string;
    performance: string;
    housing: string;
    productName: string;
    commercialName: string;
    platform: string;
}

export interface HearingAidsInfo {
    left: HearingAidInfo | undefined;
    right: HearingAidInfo | undefined;
}

class TelemetryCustomDataProvider {
    assignedHearingAids: HearingAids;
    backendVersion: string;
    hiFirmwareVersion: HearingAidsFirmwareVersion;
    targetChoice: FittingStep;
    connectionStatus: ConnectionStatus;
    connectionId: string;

    constructor() {
        this.assignedHearingAids = { left: null, right: null };
        this.backendVersion = "";
        this.hiFirmwareVersion = { left: undefined, right: undefined };
        this.targetChoice = "FITTING_CHOICE";
        this.connectionStatus = {
            left: { id: "", isPhysicallyConnected: false, isReadyForProgramming: false },
            right: { id: "", isPhysicallyConnected: false, isReadyForProgramming: false },
        };
        this.connectionId = "";
    }

    setAssignedHearingAids(hearingAids: HearingAidDto[]): void {
        this.assignedHearingAids = {
            left: createHearingAid(getHearingAidBySide(hearingAids, "Left")),
            right: createHearingAid(getHearingAidBySide(hearingAids, "Right")),
        };
    }
    setBackendVersion(backendVersion: string): void {
        this.backendVersion = backendVersion;
    }
    setHearingAidsFirmwareVersion(hearingAidsFirmwareVersion: HearingAidsFirmwareVersion): void {
        this.hiFirmwareVersion = hearingAidsFirmwareVersion;
    }
    setFittingChoice(targetChoice: FittingStep): void {
        this.targetChoice = targetChoice;
    }

    setConnectionStatus(
        hearingAidId: string,
        isReadyForProgramming: boolean,
        isPhysicallyConnected: boolean
    ): void {
        if (this.assignedHearingAids.left?.id === hearingAidId) {
            this.connectionStatus.left = {
                id: hearingAidId,
                isReadyForProgramming,
                isPhysicallyConnected,
            };
        }
        if (this.assignedHearingAids.right?.id === hearingAidId) {
            this.connectionStatus.right = {
                id: hearingAidId,
                isReadyForProgramming,
                isPhysicallyConnected,
            };
        }
    }

    getConnectionChangeProperties() {
        return {
            fittingWorkflow: this.targetChoice,
            connectionStatus: this.connectionStatus,
        };
    }

    getHearingAidsPropertiesForSide(
        hearingAid: HearingAid | null,
        firwareVersion: string | undefined
    ): HearingAidInfo | undefined {
        if (hearingAid == null) return undefined;
        const hearingAidProductInfo = this.getHearingAidProductSpecificationInfo(
            hearingAid.productInfo.commercialName,
            hearingAid.productInfo.productName
        );
        return {
            ...hearingAidProductInfo,
            id: hearingAid.id,
            currentFirmwareVersion: firwareVersion!,
            serialNumber: hearingAid.serialNumber,
            brand: hearingAid.productInfo.brand,
            demo: hearingAid.isDemo,
        };
    }

    getHearingAidProductSpecificationInfo(
        commercialName: string,
        productName: string
    ): HearingAidProductSpecificationInfo {
        return {
            family: commercialName.split(" ")?.[0] ?? "NA",
            model: productName.split(" ")?.[0] ?? "NA",
            performance: productName.split(" ")?.[1] ?? "NA",
            housing: commercialName.split(" ")?.[1] ?? "NA",
            platform: "DChipset",
            productName,
            commercialName,
        };
    }

    getHearingAidsProperties(): HearingAidsInfo {
        return {
            left: this.getHearingAidsPropertiesForSide(
                this.assignedHearingAids.left,
                this.hiFirmwareVersion.left
            ),
            right: this.getHearingAidsPropertiesForSide(
                this.assignedHearingAids.right,
                this.hiFirmwareVersion.right
            ),
        };
    }

    getHearingAidsAndVersionProperties() {
        return {
            ...this.getHearingAidsProperties(),
            ...this.getVersionProperties(),
        };
    }

    getVersionProperties() {
        return {
            softwareIdentification: "Widex Compass Cloud",
            softwareVersionFrontend: EnvironmentInfoService.frontEnd(),
            softwareVersionBackend: `v${this.backendVersion ?? "NA"}`,
        };
    }
}

export const telemetryCustomDataProvider = new TelemetryCustomDataProvider();
